@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

html,
body {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: 'Poppins', 'HelveticaNeue-Regular', 'Helvetica Neue Regular', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #2b2b2b;
  word-wrap: break-word;
  letter-spacing: 0.25px;
  background-color: #eef6fb;
}

.mainContainer {
  margin-left: 250px;
}

.sidebar {
  position: fixed;
  width: 250px;
  height: 100vh;
}
.sidebarContent {
  padding-top: 25px;
  height: 100%;
}

.rs-sidenav-header h3 {
  padding-left: 20px;
  font-weight: 600;
}

.repo-container {
  padding: 30px 50px 50px 50px;
  background-color: white;
  margin: 50px 100px;
  border-radius: 20px;
  border: 1px solid #d8eefd;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-row {
  display: flex;
}

.tagInputGroup {
  height: 40px;
  width: 300px;
}
.tagInputGroup * {
  margin: 0 5px;
}

.tagInputGroup Button {
  width: 100px;
  padding: 10px;
}
.ListContainer {
  padding-top: 40px;
}
.modal-form > Input {
  margin: 15px 0;
}

.redText {
  color: red;
  padding-top: 10px;
}

.fillVertical {
  height: 100vh;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginBox {
  width: 20%;
  min-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginBox * {
  margin: 10px;
}

.modalImageBox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.modalImage {
  width: 50%;
  border-radius: 10px;
  margin: 10px;
}

.imageCellImage {
  max-height: 120%;
}

.imageCellImage.disabled {
  opacity: 0.2;
}
